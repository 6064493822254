import React from 'react';
import GComment from './GComment';

function App() {
  return (
    <div className="App">
      <GComment />    {/* 使用 GComment 組件 */}
    </div>
  );
}

export default App;
