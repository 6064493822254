import React, { useState } from 'react';

const GComment = () => {
  const [formData, setFormData] = useState({
    doctor: '',
    surgeries: [],
    satisfactionLevels: 4,
    consultantName: ''
  });

  const doctors = [
    { id: "dr-hsieh", name: "謝宇豪" },
    { id: "dr-chu", name: "朱立箴" },
    { id: "dr-chuang", name: "莊富捷" }
  ];

  const anesthesiologists = [
    { id: "an-zhang", name: "張麻醉師" },
    { id: "an-liu", name: "劉麻醉師" },
    { id: "an-wu", name: "吳麻醉師" }
  ];

  const surgeries = [
    { id: "surgery1", name: "抽脂手術" },
    { id: "surgery2", name: "隆鼻手術" },
    { id: "surgery3", name: "隆乳手術" },
    { id: "surgery4", name: "割雙眼皮" },
    { id: "surgery5", name: "雙眼皮" }
  ];

  const satisfactionLevels = [
    { id: 4, name: "非常滿意" },
    { id: 3, name: "滿意" },
    { id: 2, name: "普通" },
    { id: 1, name: "不滿意" }
  ];

  const handleSurgeryChange = (surgeryId) => {
    setFormData(prev => {
      if (prev.surgeries.includes(surgeryId)) {
        return {
          ...prev,
          surgeries: prev.surgeries.filter(id => id !== surgeryId)
        };
      } else {
        return {
          ...prev,
          surgeries: [...prev.surgeries, surgeryId]
        };
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4 flex items-center justify-center">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-md">
        <div className="p-6">
          <h2 className="text-2xl font-bold text-center mb-6">請輸入您的療程內容</h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* 醫師選擇 */}
            <div className="space-y-2">
              <label
                htmlFor="doctor"
                className="block text-sm font-medium text-gray-700"
              >
                醫師姓名
              </label>
              <select
                id="doctor"
                value={formData.doctor}
                onChange={(e) => setFormData(prev => ({ ...prev, doctor: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-white p-2 border"
              >
                <option value="">請選擇醫師</option>
                {doctors.map((doctor) => (
                  <option key={doctor.id} value={doctor.id}>
                    {doctor.name}
                  </option>
                ))}
              </select>
            </div>

            {/* 手術選擇 */}
            <div className="space-y-2">
              <span className="block text-sm font-medium text-gray-700">
                手術名稱（可複選）
              </span>
              <div className="space-y-2">
                {surgeries.map((surgery) => (
                  <label
                    key={surgery.id}
                    className="flex items-center space-x-2 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={formData.surgeries.includes(surgery.id)}
                      onChange={() => handleSurgeryChange(surgery.id)}
                      className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <span className="text-sm text-gray-700">{surgery.name}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* 整體滿意度 */}
            <div className="space-y-2">
              <label
                htmlFor="satisfaction"
                className="block text-sm font-medium text-gray-700"
              >
                整體滿意度
              </label>
              <div className="flex items-center space-x-4">
                <input
                  type="range"
                  id="satisfaction"
                  min="1"
                  max="4"
                  step="1"
                  value={formData.satisfactionLevels || 4}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    satisfactionLevels: parseInt(e.target.value)
                  }))}
                  className="w-96 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                />
                <span className="text-sm text-gray-600">
                  {satisfactionLevels.find(level => level.id === parseInt(formData.satisfactionLevels))?.name || '非常滿意'}
                </span>
              </div>

            </div>

            {/* 諮詢師姓名 */}
            <div className="space-y-2">
              <label
                htmlFor="consultant"
                className="block text-sm font-medium text-gray-700"
              >
                諮詢師姓名
              </label>
              <input
                type="text"
                id="consultant"
                value={formData.consultantName}
                onChange={(e) => setFormData(prev => ({ ...prev, consultantName: e.target.value }))}
                placeholder="請輸入諮詢師姓名"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 border"
              />
            </div>

            {/* 提交按鈕 */}
            <button
              type="submit"
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              提交表單
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GComment;
